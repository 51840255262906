import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import MainFeatureLandlord  from "./images/assets/alexander-andrews-A3DPhhAL6Zg-unsplash.jpg";
import MainFeatureRenter  from "./images/assets/timothy-buck-psrloDbaZc8-unsplash.jpg";
import ImageAssetFAQ  from "./images/assets/patrick-perkins-3wylDrjxH-E-unsplash.jpg";


// If you want to disable the animation just use the disabled `prop` like below on your page's component
// return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Features />
    {/*<SliderCard />*/}
    {/*<TrendingCard />*/}
    <MainFeature imageSrc={MainFeatureRenter}
                 title="Find a rent and everything you need is here"/>
    <MainFeature textOnLeft
                 imageSrc={MainFeatureLandlord}
                 title="Manage your properties like never before"
    />
    {/*<Blog />*/}
    {/*<Testimonial textOnLeft={true}/>*/}
    <FAQ imageSrc={ImageAssetFAQ}/>
    <SubscribeNewsLetterForm id="news-letter"/>
    <Footer />
  </AnimationRevealPage>
);
